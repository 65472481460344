import React from 'react';
import '../styles/accessDeniedPage.scss';

const AccessDeniedPage = () => {
  return (
    <div className="accessDenied">
      <div className="bigTitle">Access Denied</div>
      <div className="description">
        Access to this site is denied. Please request access by contacting
        page-vcs-tech-oncall@amazon.com.
      </div>
    </div>
  );
};

export default AccessDeniedPage;
